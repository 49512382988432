<template>
	<div>
		<f-default-header
			:title="$t('pages.inventory.title')"
			:subtitle="$t('pages.inventory.view.subtitle')" />

		<div v-if="history.length">
			<v-expansion-panels flat>
				<v-expansion-panel>
					<v-expansion-panel-header>
						{{ $t("pages.pos.view.history") }} ({{ history.length }})
						<template v-slot:actions>
							<v-icon color="primary">$expand</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div id="preview_table_pos_wrapper">
							<table id="preview_table">
								<thead>
									<tr>
										<th>{{ $t("labels.action") }}</th>
										<th>{{ $t("labels.dataInsert") }}</th>
										<th>{{ $t("labels.user") }}</th>
										<th>{{ $t("labels.date") }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in history" :key="index">
										<td>{{ item.action }}</td>
										<td v-html="item.data"></td>
										<td>{{ item.user }}</td>
										<td>{{ item.created }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<br />
		</div>

		<f-card>
			<v-row :key="refreshList">
				<v-col cols="12">
					<table id="preview_table">
						<thead>
							<tr>
								<th>{{ $t("labels.date") }}</th>
								<th>{{ $t("labels.partNumber") }}</th>
								<th>{{ $t("labels.qtd") }}</th>
								<th class="currency">{{ $t("labels.value") }}</th>
								<th class="currency">{{ $t("labels.totalValue") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in products" :key="index">
								<td
									class="highlight-editmode"
									:aria-item-id="item.id"
									aria-item-column="date"
									:aria-item-value="item.date">
									{{ displayTableValue(item.date, isLaam, "date") }}
								</td>
								<td
									class="highlight-editmode"
									:aria-item-id="item.id"
									aria-item-column="part_number"
									:aria-item-value="item.partNumber">
									{{ item.partNumber }}
								</td>
								<td
									class="highlight-editmode"
									:aria-item-id="item.id"
									aria-item-column="quantity"
									:aria-item-value="item.quantity">
									{{ item.quantity }}
								</td>
								<td
									class="currency highlight-editmode"
									:aria-item-id="item.id"
									aria-item-column="price"
									:aria-item-value="item.value">
									{{ displayTableValue(item.value, isLaam, "price") }}
								</td>
								<td class="currency">
									{{ displayTableValue(item.totalValue, isLaam, "price") }}
								</td>
							</tr>
						</tbody>
					</table>
				</v-col>
			</v-row>
			<v-row :key="refreshList + 'total'">
				<v-col cols="12" class="totalValue">
					{{ $t("labels.totalValue") }}:
					<span
						class="highlight-editmode"
						aria-item-column="total"
						:aria-item-value="total">
						{{ displayTableValue(total, isLaam, "price") }}
					</span>
				</v-col>
			</v-row>
		</f-card>

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			<v-btn color="primary" elevation="0" large @click="download">
				{{ $t("labels.btnDownload") }}
			</v-btn>
		</f-actions-btn-holder>
		<f-delete-button @click.native="removeItem">
			{{ $t("labels.btnRemoveInventory") }}
		</f-delete-button>

		<v-dialog v-model="showContent" width="500" @click:outside="hideModal">
			<v-card>
				<v-card-title>{{ $t("labels.btnEdit") }}</v-card-title>

				<v-card-text>
					{{ $t(`labels.${currentLabel}`) }}:
					<v-text-field
						v-if="inputType == INPUT_TYPES.TEXT"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler" />
					<v-text-field
						v-if="inputType == INPUT_TYPES.CURRENCY"
						:key="refresh"
						v-money="config"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler" />
					<v-text-field
						v-if="inputType == INPUT_TYPES.NUMBER"
						v-model="ariaValues.value"
						outlined
						dense
						hide-details="auto"
						:error-messages="errorMessage"
						:error="errorMessage.length == 0 ? false : true"
						@keyup="keyUpHandler"
						type="number" />
					<v-date-picker
						full-width
						v-if="inputType == INPUT_TYPES.DATE"
						v-model="ariaValues.value"
						scrollable />
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn elevation="0" text @click="hideModal">
						{{ $t("labels.btnCancel") }}
					</v-btn>
					<v-btn elevation="0" color="primary" @click="saveModal">
						{{ $t("labels.btnSave") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import DateFormater from "../../helpers/date_formater";
import XLSX from "xlsx";
import columnInfo from "../../data/inventoryColumnsInfo";
import { VMoney } from "v-money";
import { displayTableValue } from "../../helpers/table_utils";

const INPUT_TYPES = {
	DATE: "date",
	CURRENCY: "currency",
	TEXT: "text",
	NUMBER: "number",
};

export default {
	directives: { money: VMoney },
	data: () => ({
		refreshList: 0,
		hasUpdates: false,
		id: null,
		history: [],
		ariaValues: {
			id: "",
			column: "",
			value: "",
		},
		currentLabel: "",
		showContent: false,
		inputType: null,
		INPUT_TYPES,
		errorMessage: "",
		refresh: 0,
		modal: false,
		validateFn: null,
	}),
	computed: {
		missingPriceCount() {
			return this.products.filter((p) => !p.price).length;
		},
		products() {
			return this.$store.state.inventory.view.products.map((item) => {
				item["totalPrice"] = item.price * item.quantity;
				item["value"] = item.price;
				item["totalValue"] = item.totalPrice;

				item["hasPreviousValue"] = false;
				return item;
			});
		},
		total() {
			if (this.$store.state.inventory.view.total) {
				return this.$store.state.inventory.view.total;
			} else {
				return 0;
			}
		},
		distRegion() {
			const distData = this.$store.state.inventory.view.distributorData;
			if (distData.region && distData.region == "laam") {
				return "laam";
			}
			return "br";
		},
		isLaam() {
			return this.distRegion == "laam";
		},
		config() {
			const decimal = this.isLaam ? "." : ",";
			const thousands = this.isLaam ? "," : ".";
			const prefix = this.isLaam ? "$ " : "R$ ";

			return {
				decimal,
				thousands,
				prefix,
				precision: 2,
				masked: false,
			};
		},
	},
	mounted() {
		this.id = this.$route.params.id;
		this.getData();
	},
	methods: {
		displayTableValue(value, isLaam, column) {
			return displayTableValue(value, isLaam, column);
		},
		keyUpHandler(e) {
			// console.log(e);
			this.errorMessage = this.validateFn(this.ariaValues.value, this.isLaam);
		},
		prepareEdit() {
			const items = document.querySelectorAll(".highlight-editmode");
			items.forEach((item, index) => {
				// avoid stack of listeners
				var old_element = item;
				var new_element = old_element.cloneNode(true);
				old_element.parentNode.replaceChild(new_element, old_element);

				new_element.addEventListener("click", () =>
					this.activateItem(new_element)
				);
			});
		},
		hideModal() {
			this.cleanAllItems();

			this.showContent = false;
		},
		showModal() {
			this.showContent = true;

			// set input type
			if (this.ariaValues.column == "part_number") {
				this.inputType = INPUT_TYPES.TEXT;
				this.currentLabel = "partNumber";
			} else if (this.ariaValues.column == "quantity") {
				this.inputType = INPUT_TYPES.NUMBER;
				this.currentLabel = "qtd";
			} else if (this.ariaValues.column == "price") {
				this.inputType = INPUT_TYPES.CURRENCY;
				this.currentLabel = "price";

				try {
					this.ariaValues.value = (+this.ariaValues.value).toFixed(2);
				} catch (e) {
					console.log(e);
				}
			} else if (this.ariaValues.column == "total") {
				this.inputType = INPUT_TYPES.CURRENCY;
				this.currentLabel = "total";

				this.ariaValues.id = this.id;

				try {
					this.ariaValues.value = (+this.ariaValues.value).toFixed(2);
				} catch (e) {
					console.log(e);
				}
			} else if (this.ariaValues.column == "date") {
				this.inputType = INPUT_TYPES.DATE;
				this.currentLabel = "date";

				try {
					this.ariaValues.value = new Date(this.ariaValues.value)
						.toISOString()
						.substring(0, 10);
				} catch (e) {
					console.log(e);

					this.ariaValues.value = new Date().toISOString().substring(0, 10);
				}

				this.ariaValues.value = new Date(this.ariaValues.value)
					.toISOString()
					.substring(0, 10);
			}

			// set validation function
			this.validateFn = columnInfo[this.ariaValues.column].validation;

			// pre validate data
			this.errorMessage = this.validateFn(this.ariaValues.value, this.isLaam);
		},
		async saveModal() {
			await this.$store.dispatch("inventory/saveRow", this.ariaValues);
			this.hideModal();
			this.refreshList++;
			await this.getData();
		},
		cleanAllItems() {
			const items = document.querySelectorAll(".highlight-editmode");
			items.forEach((rm_item) => rm_item.classList.remove("active"));
		},
		async activateItem(item) {
			this.cleanAllItems();

			this.ariaValues.column = item.getAttribute("aria-item-column");
			this.ariaValues.value = item.getAttribute("aria-item-value");
			if (this.ariaValues.column == "total") {
				this.ariaValues.id = this.id;
			} else {
				this.ariaValues.id = item.getAttribute("aria-item-id");
			}
			item.classList.add("active");

			// check is there missing prices
			if (this.missingPriceCount == 0 && this.ariaValues.column == "total") {
				await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.totalLockAlertTitle"),
					content: this.$t("labels.totalLockAlertContent"),
					confirm: true,
				});
				this.hideModal();
				return;
			}

			// check if the last missing price
			if (this.missingPriceCount == 1 && this.ariaValues.column == "price") {
				var response = await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.totalPriceAlertTitle"),
					content: this.$t("labels.totalPriceAlertContent"),
				});
				if (!response) {
					this.hideModal();
					return;
				}
			}

			this.refresh++;
			this.showModal();
		},
		async getData() {
			await this.$store.dispatch("inventory/get", this.id);

			const result = await this.$store.dispatch("inventory/history", this.id);

			var rowValues, partNumberData;
			this.history = result.map((item) => {
				item["action"] =
					item.actions == "replacing"
						? this.$t("pages.inventory.history.fullReplace")
						: this.$t("pages.inventory.history.fieldChange");
				item["created"] = DateFormater.fromDB(item.created_at);
				if (item.actions == "replacing") {
					item["data"] =
						item.data == "no_sell_period" ? "Período sem vendas" : item.data;
				} else {
					rowValues = JSON.parse(item.data);
					partNumberData =
						rowValues.column == "total"
							? ""
							: `Part Number: <strong>${rowValues.rel}</strong>, `;
					item[
						"data"
					] = `${partNumberData}Campo: <strong>${rowValues.column}</strong>, Valor: <strong>${rowValues.value}</strong>`;
				}
				return item;
			});

			this.prepareEdit();
		},
		back() {
			this.$router.push({
				name: "inventory-list",
			});
		},
		async download() {
			const data = this.products.map((item) => {
				return {
					[this.$t("labels.date")]: item.brDate,
					PartNumber: item.part_number,
					[this.$t("labels.qtd")]: item.quantity,
					[this.$t("labels.value")]: item.value,
				};
			});

			const filename = "download.xlsx";
			const ws = XLSX.utils.json_to_sheet(data);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "sheet");

			XLSX.writeFile(wb, filename);
		},
		async removeItem() {
			var response = await this.$store.dispatch("dialog/show", {
				title: this.$t("labels.remove"),
				content: this.$t("alerts.inventoryRemove"),
			});
			if (response) {
				await this.$store.dispatch("inventory/remove_data", this.id);
				this.back();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#preview_table_pos_wrapper {
	overflow: auto;
	max-height: 400px;
	max-width: 100%;
}
#preview_table {
	width: 100%;
	/* border-collapse: collapse; */
	border-spacing: 4px;

	th {
		text-align: left;
	}

	th,
	td {
		padding: 2px 10px;
		white-space: nowrap;
	}

	thead tr,
	tr:nth-child(even) {
		background-color: #f5f5f5;
	}

	.currency {
		text-align: right;
	}
}
.totalValue {
	text-align: right;

	span {
		font-weight: bold;
		padding-left: 10px;
		font-size: 1.2em;
	}
}
.highlight-editmode {
	&.active {
		background-color: black;
		color: white;
		font-weight: bold;
	}

	&:hover {
		background-color: #ffc107;
		cursor: pointer;
		color: white;
	}
}
</style>
