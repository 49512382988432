import { i18n } from "../main";

export default {
	date: {
		validation: (value) => "",
	},
	part_number: {
		validation: (value) => {
			if (!value || /[^0-9]/.test(value) || value.length > 11) {
				return i18n.t("validations.invalidPartNumber");
			}

			return "";
		},
	},
	quantity: {
		validation: (value) => {
			if (!value || /[^0-9]/.test(value)) {
				return i18n.t("validations.invalidNumber");
			}

			return "";
		},
	},
	price: {
		validation: (value) => "",
	},
	// validation only
	total: {
		validation: (value) => "",
	},
};
